import React from 'react';
import pagesInfo from '../../files/Pages_en.json';

const About_Title = () => {
        return (
          <div className="row">
              <div className="col-lg-12 text-left">
                  {pagesInfo.map(function(item, i) {
                      if (item.subId === "AB") {
                          return (
                              <div key={i}>
                                  <h3 className="font_type no_margin" dangerouslySetInnerHTML={{
                                      __html: item.title
                                  }}></h3>
                                  <br/>
                                  {(() => {
                                      if (item.text !== "") {
                                          return (
                                            <p  dangerouslySetInnerHTML={{
                                                __html: item.text
                                            }}></p>
                                          )
                                      }
                                  })()}
                              </div>
                          )
                      } else {
                        return null
                      }
                  })
                }
              </div>
          </div>
        )
}

export default About_Title;
