import React, { useEffect } from "react";
import AboutTitle from "./about_title";
import AboutIn from "./about_in";

const About = () => {
  useEffect(() => {
    document.title = "About Us | London Kickboxing";
    document.getElementsByTagName("META")[5].content =
      "London Kickboxing about us";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="about" className="sub">
        <div className="container">
          <AboutTitle />
          <AboutIn />
          <div id="add-reviews-list-widget"></div>
        </div>
      </section>
    </div>
  );
};

export default About;
