import React from "react";
import generalInfo from "../../files/General_en.json";

const Home_Title = () => {
  return (
    <div>
      {generalInfo.map(function (item, i) {
        if (item.ident === "T" && item.subId === "01") {
          return (
            <div key={item.subId}>
              <div className="row">
                <div className="col-lg-12 italic_font text-center">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  ></h1>
                </div>
              </div>
              {(() => {
                if (item.text !== "") {
                  return (
                    <div>
                      <br />
                      <div className="row">
                        <div className="col-lg-12 text-left">
                          <p
                            className="well"
                            dangerouslySetInnerHTML={{
                              __html: item.text,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })()}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Home_Title;
