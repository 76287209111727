import React from "react";
import generalInfo from "../../files/General_en.json";

const Home_Title_Sub = () => {
  return (
    <div>
      {generalInfo.map(function (item, i) {
        if (item.ident === "T" && item.subId === "02") {
          return (
            <div key={item.subId}>
              {(() => {
                if (item.text !== "") {
                  return (
                    <div>
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.text,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })()}
            </div>
          );
        } else {
          return null;
        }
      })}
      <span className="hidden-xs">
        <br />
      </span>
    </div>
  );
};

export default Home_Title_Sub;
