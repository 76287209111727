import React, { useEffect } from "react";
import ClassesHeader from "./classes_header";
import ClassesTitle from "./classes_title";
import ClassesFooter from "./classes_footer";
import ClassesIn from "./classes_in";
import DocsModal from "../modals/docs_modal";

const Classes = () => {
  useEffect(() => {
    document.title =
      "Kickboxing classes in London for adults and children, Ealing and Chiswick";
    document.getElementsByTagName("META")[5].content =
      "kickboxing classes in London, Chiswick and Ealing, personal training, self- defence, flexibility and fitness for all";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="classes">
        <div className="container">
          <ClassesHeader />
          <ClassesTitle />
          <ClassesIn />
          <ClassesFooter />
          <DocsModal />
        </div>
      </section>
    </div>
  );
};

export default Classes;
