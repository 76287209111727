/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Modal_Footer = () => {
  return (
    <div>
      <div className="modal-footer">
        <a href="#" className="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </a>
      </div>
    </div>
  );
};

export default Modal_Footer;
