/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Modal_Header = () => {
  return (
    <div>
      <div className="modal-header">
        <a href="#" className="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </a>
        <div className="col-xs-6">
          <img
            className="img-left img-responsive"
            src="img/lkbx_logo.png"
            alt="Kickboxing Logo"
          />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Modal_Header;
