import React from "react";
import generalInfo from "../../files/General_en.json";

const Home_Video = () => {
  return (
    <div>
      {generalInfo.map(function (item, i) {
        if (item.ident === "V") {
          return (
            <div key={i}>
              <div className="row">
                <div className="col-lg-6 col-lg-offset-3 info-item text-center">
                  <p
                    key={i}
                    className="embed-responsive-16by9 embed-responsive"
                  >
                    <iframe
                      src={item.url}
                      title="frame"
                      allowFullScreen
                    ></iframe>
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Home_Video;
