import React, { useEffect } from "react";
import FaqsTitle from "./faqs_title";
import FaqsIn from "./faqs_in";

const Faqs = () => {
  useEffect(() => {
    document.title = "London Kickboxing faqs";
    document.getElementsByTagName("META")[5].content =
      "A selection of answers to your probable questions to London Kickboxing";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="faqs" className="sub">
        <div className="container">
          <FaqsTitle />
          <FaqsIn />
        </div>
      </section>
    </div>
  );
};

export default Faqs;
