import React, { useEffect } from "react";
import ContactsTitle from "./contacts_title";
import ContactsIn from "./contacts_in";

const Contacts = () => {
  useEffect(() => {
    document.title = "London Kickboxing contact page";
    document.getElementsByTagName("META")[5].content =
      "London Kickboxing contact number and email address";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="contacts" className="sub">
        <div className="container">
          <ContactsTitle />
          <ContactsIn />
        </div>
      </section>
    </div>
  );
};

export default Contacts;
