import React, { useEffect } from "react";
import ChildrenTitle from "./children_title";
import ChildrenIn from "./children_in";

const Children = () => {
  useEffect(() => {
    document.title =
      "Children’s kickboxing near you in Chiswick and Ealing, London";
    document.getElementsByTagName("META")[5].content =
      "Children’s kickboxing classes in Chiswick and Ealing, London. Get fit have fun and learn to defend themselves";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="children">
        <div className="container">
          <ChildrenTitle />
          <ChildrenIn />
        </div>
      </section>
    </div>
  );
};

export default Children;
