import React from "react";
import generalInfo from "../../files/General_en.json";

const Home_Info = () => {
  return (
    <div>
      <br />{" "}
      {generalInfo.map(function (item, i) {
        if (item.ident === "I") {
          if (item.picture !== "") {
            return (
              <div key={i} className="row">
                <div className="col-lg-12">
                  <div className="well text-left">
                    <img
                      className={
                        "img-rounded img-responsive visible-md visible-lg " +
                        item.picPos
                      }
                      id={item.picId}
                      src={item.picture}
                      title={item.title}
                      alt={item.title}
                      width="300"
                    />
                    <img
                      className="img-responsive img-centered visible-sm visible-xs"
                      src={item.picture}
                      title={item.title}
                      alt={item.title}
                    />
                    <p className="visible-sm visible-xs"></p>
                    <p>
                      <strong>{item.title}</strong>
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={i} className="row">
                <div className="col-lg-12">
                  <div className="well text-left">
                    <p>
                      <strong>{item.title}</strong>
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            );
          }
        } else {
          return null;
        }
      })}
      <br />
    </div>
  );
};

export default Home_Info;
