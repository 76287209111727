import React from "react";

import Info from "../../files/Children_en.json";

const Children_In = () => {
  return (
    <div>
      {Info.map(function (item, i) {
        if (item.ident === "H") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.question !== "" && item.question !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.question,
                        }}
                      ></p>
                    );
                  }
                })()}
                {(() => {
                  if (item.answer !== "" && item.answer !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></p>
                    );
                  }
                })()}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      {Info.map(function (item, i) {
        if (item.ident === "B") {
          if (item.videoUrl !== "" && item.videoUrl !== " ") {
            return (
              <div key={i} className="row">
                {(() => {
                  if (item.videoPos !== "E") {
                    return (
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 info-item">
                        <p className="embed-responsive-4by3 embed-responsive">
                          <iframe
                            src="https://youtube.com/embed/JEW_2sPi6fU?rel=0"
                            title="frame"
                            allowFullScreen
                          ></iframe>
                        </p>
                      </div>
                    );
                  }
                })()}
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  {(() => {
                    if (item.question !== "") {
                      return (
                        <h4>
                          <span className="red_type bold_font">
                            {item.question}
                          </span>
                          <br />
                        </h4>
                      );
                    }
                  })()}
                  {(() => {
                    if (item.answer !== "") {
                      return (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.answer,
                          }}
                        ></p>
                      );
                    }
                  })()}
                </div>
                {(() => {
                  if (item.videoPos !== "B") {
                    return (
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 info-item">
                        <p className="embed-responsive-4by3 embed-responsive">
                          <iframe
                            src="//www.youtube.com/embed/TgnLiqlWyZg?rel=0"
                            title="frame"
                            allowFullScreen
                          ></iframe>
                        </p>
                      </div>
                    );
                  }
                })()}
              </div>
            );
          } else {
            return (
              <div key={i} className="row">
                <div className="col-lg-12 text-left">
                  {(() => {
                    if (item.question !== "") {
                      return (
                        <h4>
                          <span className="red_type bold_font">
                            {item.question}
                          </span>
                          <br />
                        </h4>
                      );
                    }
                  })()}
                  {(() => {
                    if (item.answer !== "") {
                      return (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.answer,
                          }}
                        ></p>
                      );
                    }
                  })()}
                </div>
              </div>
            );
          }
        } else {
          return null;
        }
      })}
      {Info.map(function (item, i) {
        if (item.ident === "F") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.question !== "" && item.question !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.question,
                        }}
                      ></p>
                    );
                  }
                })()}
                {(() => {
                  if (item.answer !== "" && item.answer !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></p>
                    );
                  }
                })()}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      <br />
    </div>
  );
};

export default Children_In;
