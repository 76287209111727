import React, { useEffect } from "react";
import CoursesTitle from "./courses_title";
import CoursesIn from "./courses_in";

const Courses = () => {
  useEffect(() => {
    document.title = "Courses with London Kickboxing";
    document.getElementsByTagName("META")[5].content =
      "London Kickboxing organises courses to individual requirements in kickboxing, self-defence, boxing combat fitness and weapons";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="courses" className="sub">
        <div className="container">
          <CoursesTitle />
          <CoursesIn />
        </div>
      </section>
    </div>
  );
};

export default Courses;
