import React from "react";
// import { FormFeedback } from "reactstrap";
import Info from "../../files/Courses_en.json";

const Courses_In = () => {
  return (
    <div>
      {Info.map(function (item, i) {
        if (item.ident === "H") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      {Info.map(function (item, i) {
        if (item.ident === "B") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                <h5 key={i}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  ></span>
                  <span> - </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  ></span>
                </h5>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      <br />
      {Info.map(function (item, i) {
        if (item.ident === "F") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
                {(() => {
                  if (item.url !== "") {
                    return (
                      <div>
                        <div className="row">
                          <div className="col-lg-6 col-lg-offset-3 info-item text-center">
                            <p className="embed-responsive-16by9 embed-responsive">
                              <iframe
                                src={item.url}
                                title="frame"
                                allowFullScreen
                              ></iframe>
                            </p>
                          </div>
                        </div>
                        {(() => {
                          if (item.title !== "" && item.showTitle === "Yes") {
                            return (
                              <p
                                className="text-center"
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              ></p>
                            );
                          }
                        })()}
                        <br />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        ></p>
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="row visible-md visible-lg">
        <div className="col-lg-12 text-left">
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <br />
    </div>
  );
};

export default Courses_In;
