import React from "react";
import Friends from "../../files/Friends_en.json";

const RenderH = () => {
  return (
    <div>
      {Friends.map(function (item, i) {
        if (item.subId === "H") {
          return (
            <div className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.picture !== "") {
                    return (
                      <div>
                        <img
                          className={
                            "img-rounded img-responsive visible-md visible-lg " +
                            item.picPos
                          }
                          id={item.picId}
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                        <img
                          className="img-responsive img-centered visible-sm visible-xs"
                          id="lkbx-img-bs"
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (item.title !== "" && item.showTitle === "Yes") {
                    return (
                      <h4
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      ></h4>
                    );
                  }
                })()}
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const RenderB = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div id="friends_links">
            <ul>
              {Friends.map(function (item, i) {
                if (item.ident === "B") {
                  if (item.picture === "") {
                    return (
                      <li key={i}>
                        <strong>
                          <a
                            href={item.url}
                            title={"go to " + item.text}
                            alt={item.text}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.title}
                          </a>
                        </strong>
                      </li>
                    );
                  } else {
                    return (
                      <li key={i}>
                        <a
                          href={item.url}
                          title={"go to " + item.url}
                          alt={item.title}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={item.picture}
                            className="img-responsive"
                            alt={item.title}
                          />
                        </a>
                        <a
                          href={item.url}
                          title={"go to " + item.url}
                          alt={item.title}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        ></p>
                      </li>
                    );
                  }
                } else {
                  return null;
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderX = () => {
  return (
    <div>
      {Friends.map(function (item, i) {
        if (item.ident === "X") {
          return (
            <div key={i}>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-4 text-left">
                  {(() => {
                    if (item.picture !== "") {
                      return (
                        <div>
                          <a
                            href={item.url}
                            title={"go to " + item.url}
                            alt={item.title}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={item.picture}
                              className="img-responsive"
                              alt={item.title}
                            />
                          </a>
                        </div>
                      );
                    }
                  })()}
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-8 text-left">
                  <strong>
                    <a
                      href={item.url}
                      title={"go to " + item.url}
                      alt={item.title}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.title}
                    </a>
                  </strong>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  ></p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-xs-12 text-left">
                  <p></p>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const RenderF = () => {
  return (
    <div>
      {Friends.map(function (item, i) {
        if (item.ident === "F") {
          return (
            <div className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.picture !== "") {
                    return (
                      <div>
                        <img
                          className={
                            "img-rounded img-responsive visible-md visible-lg " +
                            item.picPos
                          }
                          id={item.picId}
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                        <img
                          className="img-responsive img-centered visible-sm visible-xs"
                          id="lkbx-img-bs"
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (item.title !== "" && item.showTitle === "Yes") {
                    return (
                      <h4
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      ></h4>
                    );
                  }
                })()}
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const Friends_In = () => {
  return (
    <div>
      <RenderH />
      <RenderX />
      <RenderB />
      <RenderF />
    </div>
  );
};

export default Friends_In;
