import React from "react";
import { Link } from "react-router-dom";

var year = new Date().getFullYear();

const Footer = () => {
  return (
    <div>
      <footer className="text-center" id="page-bottom">
        <div className="footer-above">
          <div className="container-fluid">
            <div className="row">
              <div className="footer-col col-xs-6 col-xs-offset-3 col-sm-6 col-sm-offset-5 col-md-3 col-md-offset-0 col-lg-3 col-md-offset-0">
                <br />
                <img
                  src="/img/london_kickboxing_logo.png"
                  width="100"
                  className="img-responsive img-centered"
                  title=""
                  alt=""
                />
              </div>
              <div className="footer-col col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-4 col-md-5 col-md-offset-0 col-lg-6 col-lg-offset-0 text-center">
                <br />
                <div className="row">
                  <div className="footer-col col-md-4 text-left">
                    <ul>
                      <li className="page-scroll">
                        <Link to="home" title="londonkickboxing home page">
                          Home
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="classes"
                          title="londonkickboxing classes page"
                        >
                          Classes
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="children"
                          title="londonkickboxing children page"
                        >
                          Children
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="personal_training"
                          title="londonkickboxing personal training page"
                        >
                          Personal Training
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="self_defence"
                          title="londonkickboxing self defence page"
                        >
                          Self Defence
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-col col-md-4 text-left">
                    <ul>
                      <li className="page-scroll">
                        <Link
                          to="courses"
                          title="londonkickboxing courses page"
                        >
                          Courses
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="friends"
                          title="londonkickboxing friends page"
                        >
                          Friends
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link to="faqs" title="londonkickboxing faqs page">
                          FAQs
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="memberships"
                          title="londonkickboxing memberships page"
                        >
                          Memberships
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="policy"
                          title="londonkickboxing policy page"
                        >
                          Privacy & Cookies Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-col col-md-4 text-left">
                    <ul>
                      <li className="page-scroll">
                        <Link to="about" title="londonkickboxing about us page">
                          About Us
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="contacts"
                          title="londonkickboxing contact page"
                        >
                          Contacts
                        </Link>
                      </li>
                      <li className="page-scroll">
                        <Link
                          to="covid19"
                          title="londonkickboxing contact page"
                        >
                          COVID-19
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-col col-xs-12 col-sm-6 col-sm-offset-4 col-md-4 col-md-offset-0 col-lg-3 col-lg-offset-0 text-center">
                <div className="row">
                  <span className="hidden-sm hidden-xs">
                    <br />
                  </span>
                  <p>Powered by:</p>
                  <a
                    href="https://resolution.pt/"
                    target="_blank"
                    rel="noreferrer"
                    title="Resolution - Consultores Informáticos, Lda"
                  >
                    <i>Resolution - Consultores Informáticos, Lda.</i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-below">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                All content
                <i className="glyphicon glyphicon-copyright-mark"></i>
                {year} London Kickboxing.co.uk. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
