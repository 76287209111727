import React from "react";
import { Link } from "react-router-dom";
import generalInfo from "../../files/General_en.json";

const RenderAdults = () => {
  return (
    <div>
      {generalInfo.map(function (item, i) {
        if (item.ident === "A") {
          return (
            <div key={i}>
              {(() => {
                if (item.title !== "" && item.showTitle === "Yes") {
                  return (
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    ></h3>
                  );
                }
              })()}
              {(() => {
                if (item.text !== "" && item.text !== " ") {
                  return (
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></p>
                  );
                }
              })()}
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="info">
        <Link to="/classes" className="btn btn-info" role="button">
          Read More
        </Link>
        &nbsp;&nbsp;
        <a
          href="https://www.fastdd.co.uk/platinum/start.php?c=London-Kickboxing"
          target="_blank"
          rel="noreferrer"
          title="FastDD Online Direct Debit Portal"
          className="btn btn-info"
          role="button"
        >
          &nbsp;Join Now&nbsp;
        </a>
        <br />
        <br />
      </div>
    </div>
  );
};

const RenderChildren = () => {
  return (
    <div>
      {generalInfo.map(function (item, i) {
        if (item.ident === "K") {
          return (
            <div key={i}>
              {(() => {
                if (item.title !== "" && item.showTitle === "Yes") {
                  return (
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    ></h3>
                  );
                }
              })()}
              {(() => {
                if (item.text !== "" && item.text !== " ") {
                  return (
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></p>
                  );
                }
              })()}
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="info">
        <Link to="/children" className="btn btn-info" role="button">
          Read More
        </Link>
        &nbsp;&nbsp;
        <a
          href="https://www.fastdd.co.uk/platinum/start.php?c=London-Kickboxing"
          target="_blank"
          rel="noreferrer"
          title="FastDD Online Direct Debit Portal"
          className="btn btn-info"
          role="button"
        >
          &nbsp;Join Now&nbsp;
        </a>
        <br />
        <br />
      </div>
    </div>
  );
};

const RenderPersonalTraining = () => {
  return (
    <div>
      {generalInfo.map(function (item, i) {
        if (item.ident === "PT") {
          return (
            <div key={i}>
              {(() => {
                if (item.title !== "" && item.showTitle === "Yes") {
                  return (
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    ></h4>
                  );
                }
              })()}
              {(() => {
                if (item.text !== "" && item.text !== " ") {
                  return (
                    <p
                      className="small text-left"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></p>
                  );
                }
              })()}
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="info">
        <Link to="/personal_training" className="btn btn-info" role="button">
          Read More
        </Link>
        <br />
        <br />
      </div>
    </div>
  );
};

const RenderCourses = () => {
  return (
    <div>
      {generalInfo.map(function (item, i) {
        if (item.ident === "C") {
          return (
            <div key={i}>
              {(() => {
                if (item.title !== "" && item.showTitle === "Yes") {
                  return (
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    ></h4>
                  );
                }
              })()}
              {(() => {
                if (item.text !== "" && item.text !== " ") {
                  return (
                    <p
                      className="small text-left"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></p>
                  );
                }
              })()}
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="info">
        <Link to="/courses" className="btn btn-info" role="button">
          Read More
        </Link>
        <br />
        <br />
      </div>
    </div>
  );
};

const Home_Sections = () => {
  return (
    <div>
      <div className="row">
        <div className="col-sm-4 col-xs-12 col-md-4 text-center">
          <RenderAdults />
        </div>
        <div className="col-sm-4 col-xs-12 col-md-4 text-center">
          <RenderChildren />
        </div>
        <div className="col-sm-4 col-xs-12 col-md-4 text-center">
          <RenderPersonalTraining />
          <span className="hidden-xs">
            <br />
          </span>
          <RenderCourses />
        </div>
      </div>
    </div>
  );
};

export default Home_Sections;
