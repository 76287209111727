import React from "react";
import { Link } from "react-router-dom";
import Instructors from "../../files/Instructors_en.json";

const Contacts_In = () => {
  return (
    <div>
      {Instructors.map(function (item, i) {
        if (item.ident === "01") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12">
                <p>
                  Please use the form below to contact London Kickboxing,
                  alternatively email us at&nbsp;
                  <Link
                    to={"mailto:" + item.email}
                    title="email london kickboxing"
                    className="normal_links"
                  >
                    {item.email}
                  </Link>
                  <br />
                  or call {item.tel}.
                </p>
                <br />
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="row">
        <div className="col-lg-2">
          <img
            className="img-responsive left-float"
            src="img/email.png"
            width="128"
            height="128"
            alt="contact london kickboxing"
          />
        </div>
        <div className="col-lg-10">
          <form name="sentMessage" id="contactForm" noValidate>
            <div className="row control-group">
              <div className="form-group col-xs-12 floating-label-form-group controls">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  id="name"
                  required
                  data-validation-required-message="Please enter your name."
                />
                <p className="help-block text-danger"></p>
              </div>
            </div>
            <div className="row control-group">
              <div className="form-group col-xs-12 floating-label-form-group controls">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  id="email"
                  required
                  data-validation-required-message="Please enter your email address."
                />
                <p className="help-block text-danger"></p>
              </div>
            </div>
            <div className="row control-group">
              <div className="form-group col-xs-12 floating-label-form-group controls">
                <label>Message</label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder="Message"
                  id="message"
                  required
                  data-validation-required-message="Please enter a message."
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
            </div>
            <br />

            <div id="success"></div>
            <div className="row">
              <div className="form-group col-xs-12">
                <button type="submit" className="btn btn-success btn-lg">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Contacts_In;
