import React, { useEffect } from "react";
import MembershipsTitle from "./memberships_title";
import MembershipsIn from "./memberships_in";

const Memberships = () => {
  useEffect(() => {
    document.title = "London Kickboxing Memberships";
    document.getElementsByTagName("META")[5].content =
      "Kickboxing Academy in London - Memberships";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="memberships">
        <div className="container">
          <MembershipsTitle />
          <MembershipsIn />
        </div>
      </section>
    </div>
  );
};

export default Memberships;
