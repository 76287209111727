import React from "react";

import Faqs from "../../files/Faqs_en.json";

const Faqs_In = () => {
  return (
    <div>
      {Faqs.map(function (item, i) {
        if (item.ident === "H") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.question !== "" && item.question !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.question,
                        }}
                      ></p>
                    );
                  }
                })()}
                {(() => {
                  if (item.answer !== "" && item.answer !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></p>
                    );
                  }
                })()}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      {Faqs.map(function (item, i) {
        if (item.ident === "B") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.question !== "" && item.question !== " ") {
                    return (
                      <span
                        className="red_type bold_font"
                        dangerouslySetInnerHTML={{
                          __html: item.question,
                        }}
                      ></span>
                    );
                  }
                })()}
                {(() => {
                  if (item.answer !== "" && item.answer !== " ") {
                    return (
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.answer,
                          }}
                        ></span>
                        <br />
                        <br />
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      {Faqs.map(function (item, i) {
        if (item.ident === "F") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.question !== "" && item.question !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.question,
                        }}
                      ></p>
                    );
                  }
                })()}
                {(() => {
                  if (item.answer !== "" && item.answer !== " ") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.answer,
                        }}
                      ></p>
                    );
                  }
                })()}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      <br />
    </div>
  );
};

export default Faqs_In;
