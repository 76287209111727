import React, { useEffect } from "react";
import SdTitle from "./sd_title";
import SdIn from "./sd_in";

const Self_Defence = () => {
  useEffect(() => {
    document.title = "London Kickboxing self defence system";
    document.getElementsByTagName("META")[5].content =
      "With London Kickboxing you will learn to defend yourself, your family and your property";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="self_defence">
        <div className="container">
          <SdTitle />
          <SdIn />
        </div>
      </section>
    </div>
  );
};

export default Self_Defence;
