import React from "react";
import { Link } from "react-router-dom";

const Socials = () => {
  return (
    <div className="firstBar">
      <div id="header_container">
        <ul className="nav nav-pills">
          <li>
            <Link
              to="home"
              title="londonkickboxing home Page"
              className="bg-primary"
            >
              <span className="glyphicon glyphicon-home"></span>
            </Link>
          </li>
          <li>
            <Link
              to="contacts"
              title="londonkickboxing contact page"
              className="bg-primary"
            >
              <span className="glyphicon glyphicon-envelope"></span>
            </Link>
          </li>
          <li>
            <a
              href="tel:+447779593335"
              title="Call us now!"
              className="bg-primary"
            >
              <span className="glyphicon glyphicon-phone"></span>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/user/asmartialarts"
              target="_blank"
              rel="noreferrer"
              title="www.youtube.com/asmartialarts"
              className="bg-primary"
            >
              <img
                src="/img/youtube.png"
                alt="youtube"
                className="img-responsive img-centered"
                width="22"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/londonkickboxing"
              target="_blank"
              rel="noreferrer"
              title="www.facebook.com/londonkickboxing"
              className="bg-primary"
            >
              <img
                src="/img/facebook.png"
                alt="facebook"
                className="img-responsive img-centered"
                width="22"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/londonkickboxing/"
              target="_blank"
              rel="noreferrer"
              title="www.instagram.com/londonkickboxing"
              className="bg-primary"
            >
              <img
                src="/img/instagram.png"
                alt="instagram"
                className="img-responsive img-centered"
                width="22"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Socials;
