import React from "react";
import pagesInfo from "../../files/Pages_en.json";

// const divStyle = {
//   color: 'red',
// };

const CV_Title = () => {
  return (
    <div className="row">
      <div className="col-lg-12 text-left">
        {pagesInfo.map(function (item, i) {
          if (item.subId === "CV") {
            return (
              <div key={i}>
                <span className="red_type">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  ></h1>
                </span>
                {(() => {
                  if (item.text !== "") {
                    return (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      ></p>
                    );
                  }
                })()}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default CV_Title;
