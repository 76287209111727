import React from "react";
import generalInfo from "../files/General_en.json";

const Hero = () => {
  return (
    <div>
      <header id="head">
        <div className="container">
          <div className="row">
            {generalInfo.map(function (item, i) {
              if (item.ident === "L") {
                return (
                  <div key={i} className="col-lg-5 col-lg-offset-1 text-left">
                    <img
                      className="img-centered img-responsive"
                      src={item.picture}
                      title={item.title}
                      alt={item.title}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
          <br />
        </div>
      </header>
    </div>
  );
};

export default Hero;
