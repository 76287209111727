import React, { useEffect } from "react";
import PtTitle from "./pt_title";
import PtIn from "./pt_in";

const Personal_Training = () => {
  useEffect(() => {
    document.title = "Personal Training with London Kickboxing";
    document.getElementsByTagName("META")[5].content =
      "Personal training sessions in London at a training studio, your home, local park or work";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="personal_traning">
        <div className="container">
          <PtTitle />
          <PtIn />
        </div>
      </section>
    </div>
  );
};

export default Personal_Training;
