import React from "react";
// import {Link} from 'react-router';

import Prices from "../../files/Prices_en.json";
// import Gallery from '../../../public/api/files/Gallery_en.json';

const Classes_In = () => {
  return (
    <div>
      {Prices.map(function (item, i) {
        if (item.ident === "00") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12">
                <img
                  src={item.picture}
                  className="img-responsive img-centered"
                  width="1200"
                  height="600"
                  alt={item.title}
                />
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}

      <div className="row">
        <div className="col-lg-12">
          {Prices.map(function (item, i) {
            if (item.ident !== "00" && item.ident !== "99") {
              if (item.title !== "") {
                return (
                  <p key={i}>
                    <strong>
                      <span className="red_type">{item.title}</span>{" "}
                      {item.beginLine}
                    </strong>
                  </p>
                );
              } else {
                return (
                  <p key={i}>
                    <strong>
                      <span className="red_type">{item.beginLine}: </span>
                      {item.price} {item.endLine}
                    </strong>
                    {(() => {
                      if (item.extrainfo !== "") {
                        return (
                          <span className="red_type style19">
                            <strong>{item.extraInfo}</strong>
                          </span>
                        );
                      }
                    })()}
                  </p>
                );
              }
              // {(() => {
              // if (item.ident !== "99") {
              //     return (
              //         <p>{item.beginLine}</p>
              //     )
              // }
              // })()}
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Classes_In;
