import React from "react";
import Gallery from "../../files/Gallery_en.json";
import ModalHeader from "./modal_header";
import ModalFooter from "./modal_footer";

const Docs_Modal = () => {
  return (
    <div>
      {Gallery.map(function (item, i) {
        if (item.ident === "D") {
          return (
            <div key={i} className="modal fade" id={"docsModal" + item.subId}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <ModalHeader />
                  <div className="modal-body">
                    <div>
                      <h3
                        className="font_type no_margin"
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      ></h3>
                      <br />
                      <p className="embed-responsive-4by3 embed-responsive img-centered">
                        <embed
                          name="plugin"
                          id="plugin"
                          src={item.url}
                          type="application/pdf"
                        ></embed>
                      </p>
                    </div>
                  </div>
                  <ModalFooter />
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Docs_Modal;
