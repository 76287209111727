import React from "react";
import { Carousel } from "react-responsive-carousel";
import generalInfo from "../../files/General_en.json";

const items = [];

generalInfo.map(function (item, i) {
  if (item.ident === "S" && item.status === "Active") {
    items.push(item);
  }
  return null;
});

const Home_Slider = () => {
  return (
    <Carousel showArrows showThumbs={false} infiniteLoop autoPlay emulateTouch>
      {items.map((item) => {
        return (
          <div key={item._id}>
            <img src={item.picture} alt="slider" />
          </div>
        );
      })}
    </Carousel>
  );
};

export default Home_Slider;
