import React from "react";

import SelfDef from "../../files/SelfDef_en.json";

const SD_In = () => {
  return (
    <div>
      <br />
      {SelfDef.map(function (item, i) {
        if (item.ident === "H") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.picture !== "") {
                    return (
                      <div>
                        <img
                          className={
                            "img-rounded img-responsive visible-md visible-lg " +
                            item.picPos
                          }
                          id={item.picId}
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                        <img
                          className="img-responsive img-centered visible-sm visible-xs"
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (item.title !== "" && item.showTitle === "Yes") {
                    return (
                      <h4
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      ></h4>
                    );
                  }
                })()}
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      {SelfDef.map(function (item, i) {
        if (item.ident === "B") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.picture !== "") {
                    return (
                      <div>
                        <img
                          className={
                            "img-rounded img-responsive visible-md visible-lg " +
                            item.picPos
                          }
                          id={item.picId}
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                        <img
                          className="img-responsive img-centered visible-sm visible-xs"
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (item.title !== "" && item.showTitle === "Yes") {
                    return (
                      <h4
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      ></h4>
                    );
                  }
                })()}
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      {SelfDef.map(function (item, i) {
        if (item.ident === "F") {
          return (
            <div key={i} className="row">
              <div className="col-lg-12 text-left">
                {(() => {
                  if (item.picture !== "") {
                    return (
                      <div>
                        <img
                          className={
                            "img-rounded img-responsive visible-md visible-lg " +
                            item.picPos
                          }
                          id={item.picId}
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                        <img
                          className="img-responsive img-centered visible-sm visible-xs"
                          src={item.picture}
                          title={item.title}
                          alt={item.title}
                        />
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (item.title !== "" && item.showTitle === "Yes") {
                    return (
                      <h4
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      ></h4>
                    );
                  }
                })()}
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      <div className="row visible-md visible-lg">
        <div className="col-lg-12 text-left">
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <br />
    </div>
  );
};

export default SD_In;
