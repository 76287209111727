import React, { useEffect } from "react";
import PolicyTitle from "./policy_title";
import PolicyIn from "./policy_in";

const Policy = () => {
  useEffect(() => {
    document.title = "London Kickboxing Privacy and Cookies Policy ";
    document.getElementsByTagName("META")[5].content =
      "London Kickboxing Privacy and Cookies Policy";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="policy">
        <div className="container">
          <PolicyTitle />
          <PolicyIn />
        </div>
      </section>
    </div>
  );
};

export default Policy;
