import React, { useEffect } from "react";
import CvTitle from "./cv_title";
import CvIn from "./cv_in";

const Covid19 = () => {
  useEffect(() => {
    document.title = "London Kickboxing Health and Safety update COVID-19 ";
    document.getElementsByTagName("META")[5].content =
      "London Kickboxing Health and Safety update COVID-19 ";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="covid19">
        <div className="container">
          <CvTitle />
          <CvIn />
        </div>
      </section>
    </div>
  );
};

export default Covid19;
