import React from "react";
import { Link } from "react-router-dom";
// import HamburgerMenu from "./Hamburger";
import Socials from "./Socials";

const Header = () => {
  return (
    <div>
      <Socials />
      <nav className="navbar navbar-default navbar-fixed-top">
        {/* <div className="flex hidden-md hidden-lg hidden-xl">
          <HamburgerMenu />
        </div> */}
        <div className="container">
          <div className="navbar-header page-scroll">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link
              to="home"
              className="navbar-brand"
              title="londonkickboxing home page"
            >
              <span className="lk_name_s">LONDON </span>
              <span className="lk_red_s">KICKBOXING</span>
            </Link>
            <div className="navbar-text hidden-xs">
              <span className="Dummy"></span>
            </div>
            <div className="hidden">
              <span className="lkbx_flag"></span>
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              <li className="hidden">
                <Link to="home"></Link>
              </li>
              <li className="page-scroll">
                <Link to="about" title="londonkickboxing about us page">
                  About Us
                </Link>
              </li>
              <li className="page-scroll">
                <Link to="classes" title="londonkickboxing classes page">
                  Classes
                </Link>
              </li>
              <li className="page-scroll">
                <Link to="children" title="londonkickboxing children page">
                  Children
                </Link>
              </li>
              <li className="page-scroll">
                <Link
                  to="personal_training"
                  title="londonkickboxing personal training page"
                >
                  Personal Training
                </Link>
              </li>
              <li className="page-scroll">
                <Link
                  to="self_defence"
                  title="londonkickboxing self defence page"
                >
                  Self Defence
                </Link>
              </li>
              <li className="dropdown">
                <a
                  href="more"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  More<span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li className="page-scroll sub">
                    <Link to="courses" title="londonkickboxing courses page">
                      Courses
                    </Link>
                  </li>
                  <li className="page-scroll sub">
                    <Link to="friends" title="londonkickboxing friends page">
                      Friends
                    </Link>
                  </li>
                  <li className="page-scroll sub">
                    <Link to="faqs" title="londonkickboxing faqs page">
                      FAQs
                    </Link>
                  </li>
                  <li className="page-scroll sub">
                    <Link to="covid19" title="londonkickboxing covid-19 page">
                      COVID-19
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="page-scroll">
                <Link
                  to="memberships"
                  title="londonkickboxing memberships page"
                >
                  Memberships
                </Link>
              </li>
              <li className="page-scroll">
                <Link to="contacts" title="londonkickboxing contact page">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
