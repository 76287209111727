import React, { useEffect } from "react";
import FriendsTitle from "./friends_title";
import FriendsIn from "./friends_in";

const Friends = () => {
  useEffect(() => {
    document.title = "Friends of London Kickboxing";
    document.getElementsByTagName("META")[5].content =
      "London Kickboxing friends who provide and alternative or complementary service";
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="friends" className="sub">
        <div className="container">
          <FriendsTitle />
          <FriendsIn />
        </div>
      </section>
    </div>
  );
};

export default Friends;
