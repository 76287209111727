import React, { useEffect } from "react";
import HomeSlider from "./home_slider";
import HomeTitle from "./home_title";
import HomeTitleSub from "./home_title_sub";
import HomeSections from "./home_sections";
import HomeInfo from "./home_info";
import HomeMessages from "./home_messages";
import HomeVideo from "./home_video";

const Home = () => {
  useEffect(() => {
    document.title = 'London Kickboxing classes, personal training, self-defence and fitness';
    document.getElementsByTagName("META")[5].content = 'london kickboxing provides kickboxing classes in london, personal training, self defence, martial arts and fitness for men women and children';
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="home">
        <div className="container">
          <HomeSlider />
          <HomeTitle />
          <HomeTitleSub />
          <HomeSections />
          <HomeInfo />
          <HomeMessages />
          <HomeVideo />
        </div>
      </section>
    </div>
  );
};

export default Home;
